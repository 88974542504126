import {
  QuantityLabel,
  Variant,
  SubscriptionInterval,
  Subscription,
  ProductSubscriptionOption,
  OptionValue,
  Product,
  OptionType,
  ShopCollection,
} from "../sanity-schema";
import { SanityKeyed } from "../sanity-keyed";
import {
  DereferencedVariant,
  forSaleVariantFilter,
  variantsProjection,
} from "./variant-queries";
import { filters, getLocalizedString, keyof } from "./utils";
import {
  DereferencedShopCard,
  shopCardDereferencingQuery,
} from "./shop-card-queries";
import { ConversionSchema } from "../transformers/conversion";
import { flavorAndRoastCarousel } from "./quick-shop-carousel-queries";
import { globalModulesProjection } from "./global-modules";

export type DereferencedOptionType = Omit<OptionType, "optionValues"> & {
  optionValues: OptionValue[];
};

export type DereferencedSubscription = {
  intervals: Pick<
    SubscriptionInterval,
    "_id" | "length" | "presentation" | "unit"
  >[];
};

export type DereferencedSubscriptionOption = {
  _key: string;
  item: DereferencedVariant;
  quantityLabel: Pick<QuantityLabel, "label" | "quantity">;
  showSubcriptionPriceDisclaimer: boolean;
  isGiftable?: boolean;
};

export type SanityQueryType = {
  name: Product["name"];
  slug: Product["slug"];
  altBBLogo?: Product["altBBLogo"];
  description: Product["description"];
  images: Product["images"];
  isGiftable?: Product["isGiftable"];
  metaTags: Product["metaTags"];
  modules: Product["modules"];
  variants: DereferencedVariant[];
  optionTypes: DereferencedOptionType[];
  subscription: DereferencedSubscription[];
  subscriptionOptions: DereferencedSubscriptionOption[];
  subscriptionType: Product["subscriptionType"];
};

export const optionTypesProjection = `{
  ...,
  ${keyof<Variant>("optionValues")}[]->{
    ${keyof<OptionValue>("presentation")}
  }
}`;

export const subscriptionProjection = `{
  ${keyof<Subscription>("intervals")}[]->{
    ${keyof<SubscriptionInterval>("_id")},
    ${keyof<SubscriptionInterval>("length")},
    ${keyof<SubscriptionInterval>("presentation")},
    ${keyof<SubscriptionInterval>("unit")},
  },
  ${keyof<Subscription>("durations")}[]->{
    ${keyof<SubscriptionInterval>("_id")},
    ${keyof<SubscriptionInterval>("length")},
    ${keyof<SubscriptionInterval>("presentation")},
    ${keyof<SubscriptionInterval>("unit")},
  }
}`;

export const subscriptionOptionsProjection = (lang?: string) => `{
  ${keyof<SanityKeyed<ProductSubscriptionOption>>("_key")},
  ${keyof<ProductSubscriptionOption>("isGiftable")},
  ${keyof<ProductSubscriptionOption>("showSubcriptionPriceDisclaimer")},
  ${keyof<ProductSubscriptionOption>("item")}->${optionTypesProjection},
  ${keyof<ProductSubscriptionOption>("quantityLabel")}->{
    "label": ${
      lang
        ? getLocalizedString<QuantityLabel>("label", lang)
        : keyof<QuantityLabel>("label")
    },
    ${keyof<QuantityLabel>("quantity")}
  }
}`;
// prettier-ignore
const dereferencedVariants = <T>(variantsField: keyof T) => `${String(variantsField)}[]->${variantsProjection}`;

// prettier-ignore
export const forSaleDereferencedVariants = <T>(variantsField: keyof T) => `"${String(variantsField)}" : (${dereferencedVariants<T>(variantsField)})${forSaleVariantFilter}`

const productForSaleFilter = `${keyof<Product>("forSale")} == true`;

const isIndexable = (indexable: boolean = true) => {
  const logicalOperator = indexable ? "!=" : "==";

  // because not all products will have this value set, we only want to
  // exclude products that have `excludeFromIndex` explicitly set to `true`
  return `${keyof<Product>("excludeFromIndex")}${logicalOperator}true`;
};

// prettier-ignore
const dereferencedVariantsWithForSaleProjection = `${keyof<Product>("variants")}[]->{${keyof<Variant>("forSale")}}`;

// prettier-ignore
const productVariantForSaleFilter = `count((${dereferencedVariantsWithForSaleProjection})${forSaleVariantFilter}) > 0`;

export const productFilters = `
  ${productForSaleFilter}
  && ${productVariantForSaleFilter}
  && ${filters.excludeDrafts}
`;

export const indexableProductFilters = `
  ${productFilters}
  && ${isIndexable(true)}
`;

const productFilter = (slug: string) => `*[
  ${filters.whereType("product")}
  && ${filters.matchSlug<Product>("slug", slug)}
  && ${productFilters}
][0]`;

export const buildSanityQueryVariantsOnly = (slug: string) => `
${productFilter(slug)}{
  ${forSaleDereferencedVariants<Product>("variants")}
}`;

export const productRecProjection = (lang: string) => `{
  ${keyof<Product>("name")},
  ${keyof<Product>("slug")},
  ${keyof<Product>("description")},
  ${keyof<Product>("forSale")},
  ${keyof<Product>("images")},
  ${keyof<Product>("isGiftable")},
  ${forSaleDereferencedVariants<Product>("variants")},
  ${keyof<Product>("optionTypes")}[]->${optionTypesProjection},
  ${keyof<Product>("quickSubscribeCard")},
  ${keyof<Product>("subscription")}->${subscriptionProjection},
  ${keyof<Product>("subscriptionOptions")}[]
  ${subscriptionOptionsProjection(lang)},
  ${keyof<Product>("subscriptionType")},
  ${keyof<Product>("isGiftCard")},
}`;

export type DereferencedProduct = Omit<
  Product,
  "crossSellProducts" | "shopCard"
> & {
  crossSellProducts?: Product[];
  shopCard: DereferencedShopCard;
};

// prettier-ignore
export const productProjection = (lang: string, isRecursive?: boolean) => `{
  ${keyof<Product>("name")},
  ${keyof<Product>("slug")},
  ${keyof<Product>("altBBLogo")},
  ${keyof<Product>("description")},
  ${keyof<Product>("images")},
  ${keyof<Product>("metaTags")},
  ${keyof<Product>("modules")}[]{
    ...,
    ${flavorAndRoastCarousel(lang)},
    ${globalModulesProjection(lang, isRecursive)}
  },
  ${keyof<Product>("showCrossSell")},
  ${keyof<Product>("crossSellHeading")},
  ${keyof<DereferencedProduct>("crossSellProducts")}[]->${productRecProjection(
  lang,
)},
  ${forSaleDereferencedVariants<Product>("variants")},
  ${keyof<Product>("optionTypes")}[]->${optionTypesProjection},
  ${keyof<Product>("quickSubscribeCard")},
  ${shopCardDereferencingQuery},
  ${keyof<Product>("subscription")}->${subscriptionProjection},
  ${keyof<Product>("isGiftable")},
  ${keyof<Product>("subscriptionOptions")}[]${subscriptionOptionsProjection(
  lang,
)},
  ${keyof<Product>("useCustomShippingBenefits")},
  ${keyof<Product>("customShippingBenefits")},
  "${keyof<ConversionSchema>("primaryShopCollectionName")}": ${keyof<Product>(
  "shopCollections",
)}[][0]->${getLocalizedString<ShopCollection>("name", lang)},
  ${keyof<Product>("subscriptionType")},
  ${keyof<Product>("isGiftCard")},
  ${keyof<Product>("excludeFromIndex")},
  ${keyof<Product>("soldOutCtas")},
  ${keyof<Product>("forSale")}
}`;

export const productSubscriptionFields = (lang?: string) => `
${keyof<Product>("isGiftable")},
${keyof<Product>("subscriptionOptions")}[]${subscriptionOptionsProjection(
  lang,
)},
${forSaleDereferencedVariants<Product>("variants")},
${keyof<Product>("subscription")}->${subscriptionProjection},
${keyof<Product>("subscriptionType")},`;

// prettier-ignore
export const productProjectionSubs = (lang?: string) => `{
  ${productSubscriptionFields(lang)}
}`;

// prettier-ignore
export const buildSanityQuery = (lang: string, slug: string) => `
  ${productFilter(slug)}${productProjection(lang)}
`;

export const shippingCopyQuery: string = `*[
  ${filters.whereType("shippingCopy")} &&
  ${filters.excludeDrafts}
][0]`;

export const quantityGuideQuery: string = `*[
  ${filters.whereType("quantityGuide")} &&
  ${filters.excludeDrafts}
][0]`;

/**
 * SourceProduct type
 *
 * Representative of a Sanity `Product` as returned by the productsQuery
 */
export type SourceProduct = Omit<
  Product,
  | "optionTypes"
  | "shopCollections"
  | "shopCard"
  | "subscriptionOptions"
  | "variants"
> & {
  optionTypes: DereferencedOptionType[];
  shopCard?: DereferencedShopCard;
  subscriptionOptions: DereferencedSubscriptionOption[];
  variants: DereferencedVariant[];
};

export const indexableProductsQuery = `
  *[
      ${filters.whereType("product")}
      && ${indexableProductFilters}
    ] {
        ...
        , ${shopCardDereferencingQuery}
        , ${forSaleDereferencedVariants<SourceProduct>("variants")}
        , ${productSubscriptionFields()}
    }
  `;

/**
 * Query for retrieving Products from which to build RSS feed items
 */
export const rssFeedProductsQuery = `
  *[
      ${filters.whereType("product")}
      && ${indexableProductFilters}
      && ${keyof<Product>("excludeFromRssFeeds")}!=true
    ] {
        ...
        , ${keyof<Product>("optionTypes")}[]->${optionTypesProjection}
        , ${shopCardDereferencingQuery}
        , ${forSaleDereferencedVariants<SourceProduct>("variants")}
    }
  `;

import { ConversionSchema } from "../transformers/conversion";
import {
  FlavorAndRoastCarousel,
  Product,
  QuickShopCarousel,
  ShopCollection,
} from "../sanity-schema";
import { productProjection } from "./product-queries";
import { filters, getLocalizedString, keyof } from "./utils";

export const quickShopCarouselCardProjection = (
  lang: string,
  isRecursive?: boolean,
) => `{
  ...,
  ${keyof<QuickShopCarousel>("shopCards")}[]->${productProjection(
    lang,
    isRecursive,
  )},
}`;

export const quickShopCarouselCardQuery = (lang: string) => `
${filters.whereType("quickShopCarousel")} => 
${quickShopCarouselCardProjection(lang)}`;

export type FlavorAndRoastCardProjection = Pick<
  ConversionSchema,
  "name" | "slug" | "images" | "primaryShopCollectionName" | "modules"
>;

export interface FlavorAndRoastCarouselProjection {
  heading: string;
  cta: string;
  filters: {
    id: string;
    name: string;
    products: FlavorAndRoastCardProjection[];
  }[];
}

export const flavorAndRoastCarouselProjection = (lang: string) => `{
  "heading": ${getLocalizedString<FlavorAndRoastCarousel>("heading", lang)},
  "cta": ${getLocalizedString<FlavorAndRoastCarousel>("cta", lang)},
  ${keyof<FlavorAndRoastCarousel>("filters")}[]{
    "id": _key,
    "name": ${getLocalizedString<FlavorAndRoastCarousel["filters"][0]>(
      "name",
      lang,
    )},
  ${keyof<FlavorAndRoastCarousel["filters"][0]>("products")}[]->{
      ${keyof<Product>("name")},
      ${keyof<Product>("slug")},
      ${keyof<Product>("images")},
      "${keyof<ConversionSchema>("primaryShopCollectionName")}": 
      ${keyof<Product>("shopCollections")}[][0]->
      ${getLocalizedString<ShopCollection>("name", lang)},
      ${keyof<Product>("modules")},
    }
  }
}
`;

export const flavorAndRoastCarousel = (lang: string) => `
_type=="flavorAndRoastCarousel" => ${flavorAndRoastCarouselProjection(lang)}`;

import {
  EditorialFullBleed,
  Hero,
  HeroResponsive,
  ImageWithAltText,
  LocalizedString,
  MarketingCard,
  MetaTags,
  Product,
  ShopCollection,
  ShopPage,
} from "../sanity-schema";
import { SanityKeyed } from "../sanity-keyed";
import { SharedQueryKey, PageLink, queries } from "./shared";
import { filters, getLocalizedString, getSlug, keyof } from "./utils";
import { globalModulesProjection } from "./global-modules";

export enum QueryKey {
  SHOP_PAGE = "shopPage",
}

export type ShopCollectionMarketingCard = {
  marketingCard: MarketingCard;
  columnSpan: number;
  rowPlacement: number;
};

export interface ShopPageResult extends PageLink {
  altBBLogo?: ImageWithAltText;
  hero: Hero;
  heroResponsive?: HeroResponsive[];
  marketingCards?: ShopCollectionMarketingCard[];
  metaTags?: MetaTags;
  modules?: Array<SanityKeyed<EditorialFullBleed>>;
  title: LocalizedString;
  shopCollections: PageLink[];
  hideCollectionBubbles: boolean;
  pinnedProducts: string[];
}

export interface SanityQueryType {
  [QueryKey.SHOP_PAGE]: ShopPageResult;
}

// prettier-ignore
/** Defines the shape of ${@link ShopCollection} returned from Sanity */
const shopCollectionProjection = (lang: string) => `{
  "name": ${getLocalizedString<ShopCollection>("name", lang)},
  "slug": ${getSlug<ShopCollection>("slug")}
}`;

export const marketingCardsProjection = `
${keyof<ShopPage>("marketingCards")}[]{
  ...,
  ${keyof<ShopPage["marketingCards"][0]>("marketingCard")}->
}`;

// prettier-ignore
/** Defines the shape of ${@link ShopPage} returned from Sanity */
const shopPageProjection = (lang: string) => `{
  "name": ${getLocalizedString<ShopPage>("name", lang)},
  "slug": ${getSlug<ShopPage>("slug")},
  ${keyof<ShopPage>("altBBLogo")},
  ${keyof<ShopPage>("hero")},
  ${keyof<ShopPage>("heroResponsive")},
  ${marketingCardsProjection},
  ${keyof<ShopPage>("metaTags")},
  ${keyof<ShopPage>("modules")}[]{
    ...,
    ${globalModulesProjection(lang)},
  },
  ${keyof<ShopPage>("title")},
  ${keyof<ShopPage>("hideCollectionBubbles")},
  "pinnedProducts": ${keyof<ShopPage>("pinnedProducts")}[]->${keyof<Product>(
  "slug",
)}.current,
  ${keyof<ShopPage>("shopCollections")}[]->${shopCollectionProjection(lang)}
}`;

// prettier-ignore
/** Gets the link and display info for all ShopPage's */
export const allShopPageQuery = (lang: string): string => `*[
  ${filters.whereType("shopPage")}
  && ${filters.excludeDrafts}
]${shopPageProjection(lang)}
`;

// prettier-ignore
/** Uses the current slug to filter the returned collection down to a single ShopPage */
export const currentShopPageQuery = (slug: string, lang: string): string => `*[
  ${filters.whereType("shopPage")}
  && ${filters.matchSlug<ShopPage>("slug", slug)}
  && ${filters.excludeDrafts}
][0]${shopPageProjection(lang)}`;

// prettier-ignore
/** All ShopPage slugs */
export const shopPageSlugsQuery = `*[
  ${filters.whereType("shopPage")}
  && ${filters.excludeDrafts}
].${keyof<ShopPage>("slug")}.current`;

/** The Sanity query used to generate the shop/[slug].ts pages */
export const buildSanityQuery = (slug: string, lang: string): string => `{
  "${QueryKey.SHOP_PAGE}": ${currentShopPageQuery(slug, lang)},
  "${SharedQueryKey.ANNOUNCEMENT_BAR}": ${queries.announcementBarQuery},
  "${SharedQueryKey.COOKIES_NOTICE}": ${queries.cookiesNoticeQuery},
  "${SharedQueryKey.EMAIL_OPT_IN}": ${queries.emailOptInQuery},
  "${SharedQueryKey.FOOTER}": ${queries.footerQuery},
  "${SharedQueryKey.GIFT_CARD_REDEMPTION}": ${queries.giftCardRedemptionQuery},
  "${SharedQueryKey.NAV}": ${queries.navQuery},
}`;

import { ConversionSchema } from "../transformers/conversion";
import { buildSanityQuery } from "../sanity/product-queries";
import { flavorAndRoastCarousel } from "../sanity/quick-shop-carousel-queries";

type Experiment<T> = {
  id: string;
  [key: number]: T;
};

type Experiments = {
  sortBy: Experiment<boolean>;
  makeItABundle: Experiment<boolean>;
  defaultCoffeeOption: Experiment<boolean>;
};

export const experiments: Experiments = {
  sortBy: {
    id: "26",
    1: false,
    2: true,
  },
  // Note: if this experiment wins move copies in hooks/useBundle.tsx to translation groups [!Important]
  // Note: 2 address error in the bundle component
  makeItABundle: {
    id: "42",
    1: false,
    2: true,
  },
  defaultCoffeeOption: {
    id: "43",
    1: false,
    2: true,
  },
};

// Flavor and roast module starts
// Note: This will remain here in experiments to keep track of this technical debt, where only the product bella-donovan will have the flavor and roast module and this will be distributed to all other products.
// Note: To remove this, global modules are required where data can be changed from a single point and affect all places where this module is present to maintain synchronization between them, but this feature is still under discussion.

export const getFlavorAndRoastModule = (
  lang: string,
) => `*[_type =="product" && !(_id in path("drafts.**")) 
  && slug.current == "bella-donovan"][0]
  .modules[_type == "flavorAndRoastCarousel"][0]{
  _type,
  _key,
  ${flavorAndRoastCarousel(lang)}
}`;

export const conversionWithFlavorAndRoastModule = ({
  flavorAndRoastModule,
  conversion,
}: {
  flavorAndRoastModule: ConversionSchema["modules"][0];
  conversion: ConversionSchema;
}) => ({
  ...conversion,
  modules: [
    ...conversion.modules.filter(
      (module) => module._type !== "flavorAndRoastCarousel",
    ),
    flavorAndRoastModule,
  ].filter(Boolean), // In case flavorAndRoastModule is null meaning no flavor and roast module is present
});
// Flavor and roast module ends

export const bundles = [
  {
    products: ["craft-matcha", "subminimal-nanofoamer-v2"],
    bundle: "craft-matcha-and-subminimal-nanofoamer-set",
  },
  {
    products: [
      "new-orleans-style-craft-instant-coffee-blend",
      "subminimal-nanofoamer-v2",
    ],
    bundle: "nola-craft-instant-and-subminimal-nanofoamer-set",
  },
  {
    products: [
      "craft-instant-coffee-bold-single-serve",
      "kinto-day-off-tumbler",
    ],
    bundle: "craft-instant-espresso-kinto-tumbler-set",
  },
  {
    products: ["craft-instant-coffee-bold", "blue-bottle-cupping-spoon"],
    bundle: "craft-instant-espresso-and-cupping-spoon-set",
  },
];

export type BundleType = {
  productOne: ConversionSchema;
  productTwo: ConversionSchema;
  bundle: ConversionSchema;
};

export const getBundleProductsQuery = ({ currentProduct, lang }): string => {
  const { products, bundle } =
    bundles.find((bundleItem) =>
      bundleItem.products.some((product) => product === currentProduct),
    ) || {};

  if (!bundle) return null;

  return `{
    'productOne': ${buildSanityQuery(lang, products[0])},
    'productTwo': ${buildSanityQuery(lang, products[1])},
    'bundle': ${buildSanityQuery(lang, bundle)},
  }`;
};

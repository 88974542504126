import { SubscriptionQuizCopy } from "../../components/Quiz";
import {
  ChoiceQuiz,
  LastStepQuiz,
  Product,
  ProductQuiz,
  Quiz,
  TwoUp,
} from "../sanity-schema";
import { getLocalizedString, keyof } from "./utils";
import { productProjection } from "./product-queries";
import { ConversionSchema } from "../transformers/conversion";

const QUIZ_PROJECTION_DEEP = 5;

export interface LastStepProjection {
  _type: LastStepQuiz["_type"];
  announcement: string;
  heading: string;
  subheading: string;
  productRecommendations: ConversionSchema[];
  secondaryCta: LastStepQuiz["secondaryCta"];
  primaryCta: string;
}

const lastStepProjection = (lang: string) => `{  
  _type,
  "announcement": ${getLocalizedString<LastStepQuiz>("announcement", lang)},
  "heading": ${getLocalizedString<LastStepQuiz>("heading", lang)},
  "subheading": ${getLocalizedString<LastStepQuiz>("subheading", lang)},
  ${keyof<LastStepQuiz>("productRecommendations")}
  []->${productProjection(lang)},
  ${keyof<LastStepQuiz>("secondaryCta")},
  "primaryCta": ${getLocalizedString<LastStepQuiz>("primaryCta", lang)},
}`;

export interface ProductQuizProjection {
  _type: ProductQuiz["_type"];
  heading: string;
  subHeading: string;
  buttonSelectLabel: string;
  buttonAddToCartLabel: string;
  productOptions: string;
  productSchedule: string;
  product: ConversionSchema;
  lastStep: LastStepProjection;
}

const quizProductProjection = (lang: string) => `{
  ...${productProjection(lang)},
  ${keyof<Product>("hasProductionDays")}
}`;

const productQuizProjection = (lang: string) => `{
  _type,
  "heading": ${getLocalizedString<ProductQuiz>("heading", lang)},
  "subHeading": ${getLocalizedString<ProductQuiz>("subheading", lang)},
  "buttonSelectLabel": 
  ${getLocalizedString<ProductQuiz>("buttonSelectLabel", lang)},
  "buttonAddToCartLabel": 
  ${getLocalizedString<ProductQuiz>("buttonAddToCartLabel", lang)},
  "productOptions": 
  ${getLocalizedString<ProductQuiz>("productOptions", lang)},
  "productSchedule": 
  ${getLocalizedString<ProductQuiz>("productSchedule", lang)},
  ${keyof<ProductQuiz>("product")}[0]->${quizProductProjection(lang)},
  ${keyof<ProductQuiz>("lastStep")}[0]
  ${lastStepProjection(lang)},
}`;

type ChoiceQuizFilters = ChoiceQuiz["choices"]["filters"][0];

export interface ChoiceQuizProjection {
  _type: ChoiceQuiz["_type"];
  heading: string;
  subHeading: string;
  choices: {
    selectionType: string;
    products?: ProductQuizProjection[];
    filters?: {
      heading: string;
      image: ChoiceQuizFilters["image"];
      nextStep: ChoiceQuizProjection;
    }[];
  };
}

const choiceQuizProjection = (lang: string, limit: number) => {
  if (limit <= 0) {
    return "";
  }

  return `{
    _type,
    "heading": ${getLocalizedString<ChoiceQuiz>("heading", lang)},
    "subHeading": ${getLocalizedString<ChoiceQuiz>("subheading", lang)},
    ${keyof<ChoiceQuiz>("choices")}{
      ${keyof<ChoiceQuiz["choices"]>("selectionType")},
      ${keyof<ChoiceQuiz["choices"]>("products")}[]
      ${productQuizProjection(lang)},
      ${keyof<ChoiceQuiz["choices"]>("filters")}[] {
        "heading": ${getLocalizedString<ChoiceQuizFilters>("heading", lang)},
        ${keyof<ChoiceQuizFilters>("image")},
        ${keyof<ChoiceQuizFilters>("nextStep")}[0]
        ${choiceQuizProjection(lang, limit - 1)},
      }
    },
  }`;
};

export interface QuizProjection {
  _type: Quiz["_type"];
  _id: string;
  introduction: TwoUp;
  start: ChoiceQuizProjection;
  subscriptionQuizCopy: SubscriptionQuizCopy;
  valueProps: string[];
}

export const quizProjection = (lang: string) => `
  _type == "quiz" => {
    _type,
    _id,
    ${keyof<Quiz>("introduction")},
    "valueProps": ${keyof<Quiz>("valueProps")}[]["${lang}"],
    ${keyof<Quiz>("start")}[0]
    ${choiceQuizProjection(lang, QUIZ_PROJECTION_DEEP)}
  }
`;

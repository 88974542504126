import { filters, keyof } from "./utils";
import {
  internalGroqTypeReferenceTo,
  ShippingCopy,
  SubscriptionCollectionsPreviewModule,
  SubscriptionPage,
} from "../sanity-schema";
import { shopCollectionProjection } from "./collection-queries";
import { shippingCopyQuery } from "./product-queries";
import { quizProjection } from "./quiz-queries";
import { globalModulesProjection } from "./global-modules";

type ResolveAnyReference<T> = T extends {
  _type: "reference";
  _ref: string;
  _key: string;
  [internalGroqTypeReferenceTo]?: infer U extends string;
}
  ? {
      _ref: string;
      _type: U;
    }
  : T;

type ModulesUnion = ResolveAnyReference<SubscriptionPage["modules"][number]>;

export type SanitySubscriptionPage = Omit<SubscriptionPage, "modules"> & {
  modules: ModulesUnion[];
};

export enum QueryKey {
  SUBSCRIPTION_PAGE = "subscriptionPage",
  SHIPPING_COPY = "shippingCopy",
}

export type SubscriptionCollectionsPreviewCopy = {
  assortmentButtonTextBeforeCost: string;
  detailRecommendationLabel: string;
  detailExampleLabel: string;
  selectionTitle: string;
  selectionDescription: string;
  selectionQuantityLabel: string;
  selectionDeliveryLabel: string;
  selectionButtonTextBeforeCost: string;
  selectionButtonTextAfterCost: string;
  selectionButtonTextPrePaidSub: string;
  moduleGoToCollectionButtonText: string;
};

export type SanityQueryType = {
  [QueryKey.SUBSCRIPTION_PAGE]: SanitySubscriptionPage;
  [QueryKey.SHIPPING_COPY]: ShippingCopy;
};

export const subscriptionCollectionsPreviewModuleProjection = (
  lang: string,
  isRecursive?: boolean,
): string => `{
  ...,
  ${keyof<SubscriptionCollectionsPreviewModule>(
    "shopCollections",
  )}[]->${shopCollectionProjection(lang, isRecursive)}
}`;

// prettier-ignore
/** Builds the projection for the SubscriptionPage.modules */
const modulesProjection = (lang: string) => `{
  ...,
  ${globalModulesProjection(lang)},
  _type=="subscriptionCollectionsPreviewModule" => ${subscriptionCollectionsPreviewModuleProjection(
    lang,
  )},
  _type=="reference" => @->{
    ${quizProjection(lang)},
  }
}`

// prettier-ignore
/** Builds the projection for the SubscriptionPage */
const subscriptionPageProjection = (lang: string) => `{
  ..., 
  ${keyof<SubscriptionPage>("modules")}[]${modulesProjection(lang)}
}`

// prettier-ignore
/** Gets the first subscription page */
const firstSubscriptionPage = (lang: string) => `*[
  ${filters.whereType("subscriptionPage")}
  && ${filters.excludeDrafts}
][0]${subscriptionPageProjection(lang)}`;

// prettier-ignore
/** The Sanity query used to supply data to the subscription/index page */
export const buildSanityQuery = (lang: string): string => `{
  "${QueryKey.SUBSCRIPTION_PAGE}": ${firstSubscriptionPage(lang)},
  "${QueryKey.SHIPPING_COPY}": ${shippingCopyQuery},
}`;

import {
  CategoryExplore,
  EditorialFullBleed,
  Filter,
  Hero,
  HeroResponsive,
  ImageWithAltText,
  LocalizedString,
  MetaTags,
  ShopCollection,
  Tag,
} from "../sanity-schema";
import { SanityKeyed } from "../sanity-keyed";
import { PageLink, BasePropsWithSlug } from "./shared";
import {
  allShopPageQuery,
  marketingCardsProjection,
  ShopCollectionMarketingCard,
} from "./shop-queries";
import { filters, getLocalizedString, getSlug, keyof } from "./utils";
import { globalModulesProjection } from "./global-modules";

export enum QueryKey {
  NAV_LINKS = "navLinks",
  SHOP_COLLECTION = "shopCollection",
}

export type SanityShopCollectionFilter = Pick<Filter, "name"> & {
  display: string;
  slug: string;
  tags: (Pick<Tag, "name"> & { slug: string; display: string })[];
};

export interface SanityShopCollection extends PageLink {
  altBBLogo?: ImageWithAltText;
  hero: Hero;
  heroResponsive?: HeroResponsive[];
  marketingCards?: ShopCollectionMarketingCard[];
  metaTags?: MetaTags;
  modules?: Array<SanityKeyed<CategoryExplore | EditorialFullBleed>>;
  title: LocalizedString;
  filters: SanityShopCollectionFilter[];
}

export interface ShopCollectionNavQueryResult extends PageLink {
  shopCollections: PageLink[];
  hideCollectionBubbles?: boolean;
}

export interface SanityQueryType extends BasePropsWithSlug {
  [QueryKey.NAV_LINKS]: ShopCollectionNavQueryResult[];
  [QueryKey.SHOP_COLLECTION]: SanityShopCollection;
}

// prettier-ignore
/** Defines the shape of ${@link Tag} returned from Sanity */
const tagProjection = (lang: string) => `{
  "display": ${getLocalizedString<Tag>("display", lang)},
  ${keyof<Tag>("name")},
  "slug": ${getSlug<Tag>("slug")}
}`;

// prettier-ignore
/** Defines the shape of ${@link Filter} returned from Sanity */
const filterProjection = (lang: string) => `{
  ${keyof<Filter>("name")},
  "display": ${getLocalizedString<Filter>("display", lang)},
  "slug": ${getSlug<Filter>("slug")},
  "tags": ${keyof<Filter>("tags")}[]${tagProjection(lang)}
}`;

// prettier-ignore
/** Defines the shape of ${@link ShopCollection} returned from Sanity */
export const shopCollectionProjection = (lang: string, isRecursive?: boolean) => `{
  "name": ${getLocalizedString<ShopCollection>("name", lang)},
  "slug": ${getSlug<ShopCollection>("slug")},
  ${keyof<ShopCollection>("altBBLogo")},
  ${keyof<ShopCollection>("hero")},
  ${keyof<ShopCollection>("heroResponsive")},
  ${marketingCardsProjection},
  ${keyof<ShopCollection>("metaTags")},
  ${keyof<ShopCollection>("modules")}[]{
    ...,
    ${globalModulesProjection(lang, isRecursive)},
  },
  ${keyof<ShopCollection>("title")},
  "filters": coalesce(${keyof<ShopCollection>("filters")}[]->${filterProjection(
  lang,
)}, [])
}`;

/** Uses the current slug to filter the returned collection down to a single
 * ShopCollection */
const currentShopCollectionQuery = (slug: string, lang: string): string => `*[
  ${filters.whereType("shopCollection")}
  && ${filters.matchSlug<ShopCollection>("slug", slug)}
  && ${filters.excludeDrafts}
][0]${shopCollectionProjection(lang)}`;

/** The Sanity query used to generate the collection/[slug].ts pages */
export const buildSanityQuery = (slug: string, lang: string): string => `{
    "${QueryKey.NAV_LINKS}": ${allShopPageQuery(lang)},
    "${QueryKey.SHOP_COLLECTION}": ${currentShopCollectionQuery(slug, lang)},
  }`;

import {
  flavorAndRoastCarouselProjection,
  quickShopCarouselCardProjection,
} from "./quick-shop-carousel-queries";
import { cafeGroupFeatureProjection, cafeLocatorProjection } from "./shared";
import { subscriptionCollectionsPreviewModuleProjection } from "./subscription-queries";

// List of simple modules
const simpleModules = [
  "appDownload",
  "articleCardCarousel",
  "bulletedKeyValueList",
  "carouselInlineCaption",
  "categoryCarousel",
  "categoryExplore",
  "categoryGrid",
  "containerModule",
  "copyColumnFreeFlow",
  "editorialFeature",
  "editorialFullBleed",
  "editorialIconBlock",
  "emailSignUp",
  "hero",
  "oneClickSubscribeModule",
  "promotionalBanner",
  "quote",
  "stickyLink",
  "twoUp",
  "vimeoPlayer",
];

// Generate projection for simple modules
const generateSimpleModulesProjection = () =>
  simpleModules
    .map((module) => `module == "${module}" => ${module}`)
    .join(",\n");

// Modules with specific projections
const generateOtherModulesProjection = (lang: string) => {
  const modulesWithProjection = {
    quickShopCarousel: quickShopCarouselCardProjection(lang, false),
    flavorAndRoastCarousel: flavorAndRoastCarouselProjection(lang),
    cafeLocator: cafeLocatorProjection,
    cafeGroupFeature: cafeGroupFeatureProjection,
    subscriptionCollectionsPreviewModule:
      subscriptionCollectionsPreviewModuleProjection(lang, false),
  };

  return Object.entries(modulesWithProjection)
    .map(([key, value]) => `module == "${key}" => ${key} ${value}`)
    .join(",\n");
};

// Global modules projection
export const globalModulesProjection = (
  lang: string,
  // isRecursive is used to determine if we want to include the recursive projection
  // this prevent maximum call stack size exceeded error
  isRecursive: boolean = true,
) => `
  _type == 'globalModule' => @->{
    "_type": *[_type == 'globalModule' && _id == ^._id][0].module,
    _type == "globalModule" => ${
      isRecursive
        ? `null`
        : `select(
          ${generateSimpleModulesProjection()},
          ${generateOtherModulesProjection(lang)}
        )`
    }
  }
`;

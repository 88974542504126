import { GrowthBook } from "@growthbook/growthbook";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
// @ts-ignore
import { autoAttributesPlugin } from "@growthbook/growthbook/plugins";
import { isFeatureEnabled } from "../utils/is-feature-enabled";

// Create a client-side GrowthBook instance
const gb = new GrowthBook({
  apiHost: "https://cro-lab.novapower.io:3100",
  clientKey: "sdk-KcJSFJSwKcC2wzT9",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

export const GrowthBookWrapper = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const isTestingEnabled = isFeatureEnabled(
      process.env.NEXT_PUBLIC_ENABLE_AB_TESTING,
    );

    if (!isTestingEnabled) return undefined;

    // Let the GrowthBook instance know when the URL changes so the active
    // experiments can update accordingly
    const updateGrowthBookURL = () => gb.setURL(window.location.href);

    // Load features from the GrowthBook API and keep them up-to-date
    gb.init({ streaming: true });
    const setAttributes = autoAttributesPlugin();
    setAttributes(gb);

    // Subscribe to route change events and update GrowthBook
    router.events.on("routeChangeComplete", updateGrowthBookURL);
    return () => router.events.off("routeChangeComplete", updateGrowthBookURL);
  }, []);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
